@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/indigo.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/purple.css';
@import '@radix-ui/colors/violet.css';

/*div.container{
    padding-left: 0px;
}*/

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

a.navbar-brand img {
    width: 200px;
    left: -10px;
}

@media (min-width: 768px) {
    a.navbar-brand img {
        width: 300px;
    }
}

    html {
        font-size: 14px;
    }
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

li > button{
    all: unset;
}

.NavigationMenuRoot {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 1;
    padding: 0 3rem;
    background: #fff;
    box-shadow: 0 5px 8px rgba(0, 0, 0, .05);
}

.NavigationMenuRoot div {
    width: 99vw;
    max-width: 1920px;
}

.NavigationMenuList {
    display: flex;
    max-height: 300px;
    overflow-y: auto;
    justify-content: space-between;
    background-color: white;
    padding: 4px;
    border-radius: 0px;
    list-style: none;
    /*box-shadow: 0 5px 8px rgba(0, 0, 0, .05);*/
    margin: 0;
}

/*.NavigationMenuList::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    .NavigationMenuList::-webkit-scrollbar-thumb {
        background-color: #74bc1f;
        border-radius: 4px;
    }

        .NavigationMenuList::-webkit-scrollbar-thumb:hover {
            background-color: #74bc1f;
        }*/

.NavigationMenuTrigger,
.NavigationMenuLink {
    padding: 8px 8px;
    outline: none;
    user-select: none;
    /*font-weight: 500;*/
    line-height: 1;
    border-radius: 4px;
    font-size: 15px;
    color: #000;
}

    .NavigationMenuTrigger > a{
        color: #000;
    }

    .NavigationMenuTrigger:hover > a {
        color: #fff;
    }

    .NavigationMenuTrigger:focus,
    .NavigationMenuLink:focus {
        box-shadow: 0 0 0 2px #74bc1f;
    }

    .NavigationMenuTrigger:hover,
    .NavigationMenuLink:hover {
        background-color: #74bc1f;
        color: #fff;
    }

.NavigationMenuTrigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*gap: 2px;*/
}

.NavigationMenuLink {
    display: block;
    text-decoration: none;
    font-size: 15px;
    line-height: 1;
}

.NavigationMenuContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 175px;
    animation-duration: 250ms;
    animation-timing-function: ease;
}

    .NavigationMenuContent > img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: auto;
        height: 300px;
    }

    .NavigationMenuContent[data-motion='from-start'] {
        animation-name: enterFromLeft;
    }

    .NavigationMenuContent[data-motion='from-end'] {
        animation-name: enterFromRight;
    }

    .NavigationMenuContent[data-motion='to-start'] {
        animation-name: exitToLeft;
    }

    .NavigationMenuContent[data-motion='to-end'] {
        animation-name: exitToRight;
    }

@media only screen and (min-width: 600px) {
    .NavigationMenuContent {
        width: 95vw;
    }
}

.NavigationMenuIndicator {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 10px;
    top: 100%;
    overflow: hidden;
    z-index: 1;
    transition: width, transform 250ms ease;
}

    .NavigationMenuIndicator[data-state='visible'] {
        animation: fadeIn 200ms ease;
    }

    .NavigationMenuIndicator[data-state='hidden'] {
        animation: fadeOut 200ms ease;
    }

.NavigationMenuViewport {
    position: relative;
    transform-origin: top center;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    height: var(--radix-navigation-menu-viewport-height);
    transition: width, height, 300ms ease;
}

    .NavigationMenuViewport[data-state='open'] {
        animation: scaleIn 200ms ease;
    }

    .NavigationMenuViewport[data-state='closed'] {
        animation: scaleOut 200ms ease;
    }

@media only screen and (min-width: 600px) {
    .NavigationMenuViewport {
        width: var(--radix-navigation-menu-viewport-width);
    }
}

.List {
    display: grid;
    padding: 22px;
    margin: 0;
    column-gap: 10px;
    list-style: none;
}

@media only screen and (min-width: 600px) {
    .List.one {
        width: 500px;
        grid-template-columns: 0.75fr 1fr;
    }

    .List.two {
        width: 600px;
        grid-auto-flow: column;
        grid-template-rows: repeat(3, 1fr);
    }
}

.List > a {
    display: block;
    outline: none;
    text-decoration: none;
    user-select: none;
    padding: 12px;
    border-radius: 6px;
    font-size: 15px;
    line-height: 1;
}

.List > a:focus {
    box-shadow: 0 0 0 2px #74bc1f;
}

.List > a:hover {
    background-color: rgba(182, 255, 0, .2);
}

.ListItemHeading {
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 5px;
    color: var(--violet-12);
}

.List > a {
    color: var(--mauve-11);
    line-height: 1.4;
    font-weight: initial;
}

.Callout {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(9,121,10,1) 0%, rgba(142,255,0,1) 100%);
    border-radius: 6px;
    padding: 25px;
    text-decoration: none;
    outline: none;
    user-select: none;
}

    .Callout:focus {
        box-shadow: 0 0 0 2px #74bc1f;
    }

.CalloutHeading {
    color: white;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 16px;
    margin-bottom: 7px;
}

.CalloutText {
    color: var(--mauve-4);
    font-size: 14px;
    line-height: 1.3;
}

.ViewportPosition {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 100%;
    /*left: 0;*/
    perspective: 2000px;
}

.CaretDown {
    position: relative;
    color: #000;
    top: 1px;
    transition: transform 250ms ease;
}

[data-state='open'] > .CaretDown {
    transform: rotate(-180deg);
}

.NavigationMenuList:hover [data-state='open'] > .CaretDown {
    color: #fff;
}

.Arrow {
    position: relative;
    top: 70%;
    background-color: white;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border-top-left-radius: 2px;
}

.breadcrumb-not-active {
    color: #cccccc;
}

.breadcrumb-active {
    color: #000000;
}

.breadcrumb-arrow {
    margin-left: 10px;
    margin-right: 10px;
}

.crumbs > a:hover {
    color: #74bc1f !important;
}

.hoverLinks > a:hover {
    color: #74bc1f !important;
}

.brandHoverLinks > a:hover {
    color: #44D62C !important;
}

@media screen and (max-width: 1100px) {
    .NavigationMenuRoot{
        display: none;
    }
}

@media screen and (max-width: 440px) {
    .number-hide {
        display: none;
    }
}

@keyframes enterFromRight {
    from {
        opacity: 0;
        transform: translateX(200px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes enterFromLeft {
    from {
        opacity: 0;
        transform: translateX(-200px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes exitToRight {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(200px);
    }
}

@keyframes exitToLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-200px);
    }
}

@keyframes scaleIn {
    from {
        opacity: 0;
        transform: rotateX(-30deg) scale(0.9);
    }

    to {
        opacity: 1;
        transform: rotateX(0deg) scale(1);
    }
}

@keyframes scaleOut {
    from {
        opacity: 1;
        transform: rotateX(0deg) scale(1);
    }

    to {
        opacity: 0;
        transform: rotateX(-10deg) scale(0.95);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.header {
    background: #fff;
    width: 100%;
    height: 100px;
    padding: 0 1.5rem;
    transition: 0.3s ease all;
}

.brandHeader {
    /*box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;*/
    /*box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;*/
    border-bottom: 2px solid #999;
}

.header__content {
    /*overflow: hidden;*/
    color: #000;
    margin: 0 auto;
    max-width: 1920px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;
}

.header__content__logo img {
    width: 200px;
}

.header__content__bradlogo img {
    width: 150px;
}

.header__content__nav {
    top: 0;
    right: 100%;
    /*bottom: 0;*/
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: center;*/
    /*text-align: center;*/
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(2px);
    transform: translate(0);
    transition: 0.3s ease transform;
}

.header__content__nav ul {
    position: relative;
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
}

.header__content__nav ul li {
    /*width: 90%;*/
    position: relative;
}

.header__content__nav ul li a {
    text-decoration: none;
    color: inherit;
    padding: 0.75rem 0.75rem;
    border-radius: 12px;
    transition: 0.3s ease all;
    color: #fff;
    display: flex;
}

    .header__content__nav ul li ul {
        display: none;
        position: absolute;
        /*top: 5%;*/
        left: 0;
        width: 200px;
        background: #fff;
        /*z-index: 10;*/
        margin: 0;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
    }

.header__content__nav ul li ul li{
    padding: 5px 10px;
}

.header__content__nav ul li ul li a{
    font-size: 16px;
    /*color: #000;*/
}

.header__content__nav ul li:hover ul {
    display: block;
}

.header__content__nav ul i {
    color: #fff;
    margin-left: 0 !important;
}

.header__content__nav ul li a:hover {
    background: rgba( 116, 188, 31, 0.25);
}

.header__content__nav ul li a:active {
    border-radius: calc(12px + 6px);
    background: linear-gradient( rgba(#fff, 0.1), rgba(#fff, 0.25) );
}

.header__content__nav button {
    cursor: pointer;
    outline: none;
    padding: 0.75rem 1.25rem;
    border-radius: 12px;
    font-size: 1rem;
    font-family: inherit;
    background: #74bc1f;
    color: #fff;
    border: 1px solid transparent;
    transition: 0.3s ease all;
}

.header__content__nav button:hover {
    border-color: #74bc1f;
    background: rgba(#74bc1f, 0.1);
    color: #74bc1f;
}

.header__content__nav.isMenu {
    transform: translate(100%);
}

.header__content__toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 2rem;
    transition: 0.3s ease all;
    position: relative;
}

.header__content__toggle:hover {
    color: #74bc1f;
}

.confirm-box {
    z-index: 1000;
    position: absolute;
    left: 45%;
    top: 50%;
}

    @media (min-width: 768px) {
        .header {
            padding: 0 3rem;
        }

        .header__content__nav {
            transform: none;
            flex-direction: row;
            background: transparent;
            width: auto;
            height: 100%;
            position: static;
        }

        .header__content__logo img {
            width: 300px;
            left: -10px;
        }

        .header__content__brandlogo img {
            /*display: grid;*/
            width: 200px;
            /*aspect-ratio: 6/1;*/
            max-height: auto;
            object-fit: contain;
            /*left: -10px;*/
        }

        .header__content__nav ul {
            flex-direction: row;
            margin-bottom: 0;
            /*margin-right: calc(0.5rem + 16px);*/
            background: none;
        }

            .header__content__nav ul li a {
                font-size: 16px;
                color: #000;
            }

            .header__content__nav ul i {
                color: #000;
            }

        .header__content__toggle {
            display: none;
        }
    }

    @media (max-width: 930px) {
        .header__content__nav ul li a {
            font-size: 12px;
        }

        .header__content__logo img {
            width: 200px;
            left: -10px;
        }

        .header__content__brandlogo img {
            width: 140px;
            height: auto;
            object-fit: fill;
            /*left: -10px;*/
        }
    }

    @media (max-width: 767px) {
        .header__content__nav ul li ul {
            position: relative;
            width: 100%;
            background: none;
        }

        .header__content__nav ul li a {
            font-size: 20px;
        }
    }

@media (max-width: 768px) {

    .confirm-box {
        z-index: 1000;
        position: absolute;
        left: 30%;
        top: 50%;
    }
}

@media (max-width: 425px) {
    .confirm-box {
        z-index: 1000;
        position: absolute;
        left: 15%;
        top: 50%;
    }
}

@media (max-width: 375px) {
    .confirm-box {
        z-index: 1000;
        position: absolute;
        left: 10%;
        top: 50%;
    }
}

@media (max-width: 320px) {
    .confirm-box {
        z-index: 1000;
        position: absolute;
        left: 3%;
        top: 50%;
    }
}

