/* #region Global Mixin Definitions*/
/* #endregion */
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-react-buttons/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-react-calendars/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-react-dropdowns/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-react-inputs/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-react-notifications/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-richtexteditor/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
* {
  scroll-behavior: smooth;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #74bc1f;
}

html::-webkit-scrollbar {
  width: 0.5vw;
}

html::-webkit-scrollbar-thumb {
  background-color: #74bc1f;
  border-radius: 10px;
}

html::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.close {
  border: none;
  background-color: #f1f1f1;
  opacity: 0.5;
  transition: ease-in-out all 0.3s;
}
.close:hover {
  opacity: 1;
}

.toast-success {
  background-color: #00B74A !important;
  color: #f1f1f1 !important;
}
.toast-success .e-toast-title {
  color: #f1f1f1 !important;
  font-size: 1.1em;
  font-weight: bold;
}
.toast-success .e-toast-content, .toast-success .e-toast-close-icon {
  color: #f1f1f1 !important;
}

.toast-danger {
  background-color: #F93154 !important;
  color: #f1f1f1 !important;
}
.toast-danger .e-toast-title {
  color: #f1f1f1 !important;
  font-size: 1.1em;
  font-weight: bold;
}
.toast-danger .e-toast-content, .toast-danger .e-toast-close-icon {
  color: #f1f1f1 !important;
}

.toast-info {
  background-color: #39C0ED !important;
  color: #f1f1f1 !important;
}
.toast-info .e-toast-title {
  color: #f1f1f1 !important;
  font-size: 1.1em;
  font-weight: bold;
}
.toast-info .e-toast-content, .toast-info .e-toast-close-icon {
  color: #f1f1f1 !important;
}

.confirm-box {
  position: fixed !important;
  top: 45% !important;
  z-index: 99999;
}

.confirm-button {
  background-color: #00B74A !important;
  color: #f1f1f1 !important;
}
.confirm-button::after {
  font-family: "Font Awesome 6 Pro";
  content: "\f058";
  padding-left: 0.5em;
}

.cancel-button {
  background-color: #F93154 !important;
  color: #f1f1f1 !important;
}
.cancel-button::after {
  font-family: "Font Awesome 6 Pro";
  content: "\f057";
  padding-left: 0.5em;
}

label {
  font-size: 0.75em;
  font-weight: 500;
  color: #363636;
}

.error-message, .ErrorText {
  font-size: 0.75em;
  color: #f00;
  font-style: italic;
}

.e-tick-value {
  color: #aeaeae !important;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.hoverLinks a {
  text-decoration: none;
  color: #f1f1f1;
  transition: ease-in-out all 0.3s;
}
.hoverLinks a svg {
  fill: #f1f1f1;
  transition: ease-in-out all 0.3s;
}
.hoverLinks a:hover {
  color: #14a44d;
}
.hoverLinks a:hover svg {
  fill: #74bc1f;
}

.brandHoverLinks a {
  text-decoration: none;
  color: #f1f1f1;
  transition: ease-in-out all 0.3s;
}
.brandHoverLinks a svg {
  fill: #f1f1f1;
  transition: ease-in-out all 0.3s;
}
.brandHoverLinks a:hover {
  color: #44D62C;
}
.brandHoverLinks a:hover svg {
  fill: #44D62C;
}

.login a:hover {
  color: #14a44d;
}

.required-icon {
  margin: 0 2px;
  color: #f00;
}

.mega-menu {
  width: 400px;
  max-width: 50vw;
}

h1 {
  font-size: 2em;
}

.btn-admin {
  width: 250px;
  margin: 0 0.5em 0.5em 0;
}

.overlay {
  position: relative;
}
.overlay span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  font-weight: 800;
  line-height: 1;
}
@media (max-width: 800px) {
  .overlay span {
    font-size: 25px;
  }
}

/*----------------------------Product card styles------------------------------*/
.product-card {
  position: relative;
  overflow: hidden;
  width: 250px;
  max-height: 480px;
  color: #222;
  transition: 0.4s;
}
.product-card .product-image {
  width: 100%;
  height: 200px;
  cursor: pointer;
  margin-top: 15px;
}
.product-card .card-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product-card .sale {
  display: grid;
  position: absolute;
  top: 10px;
  left: 10px;
  color: #f1f1f1;
  font-size: 14px;
  place-content: center;
  border-radius: 50%;
  font-weight: 600;
  background: #74bc1f;
}
.product-card .wishlist {
  position: absolute;
  top: 0;
  right: 0;
  background: #f1f1f1;
  transform: translateX(50px);
  transition: transform 0.3s ease;
}
.product-card .wishlist i {
  cursor: pointer;
}
.product-card .wishlist i:hover {
  color: #74bc1f;
}
.product-card .product-description {
  width: 100%;
}
.product-card .product-description p {
  font-size: 14px;
  line-height: 1.5;
}
.product-card .cart-btn {
  margin-bottom: 15px;
  overflow-y: hidden;
}
.product-card .cart-btn button {
  display: block;
  width: 100%;
  border: none;
  outline: none;
  background-color: rgba(116, 188, 31, 0.5);
  padding: 10px 0;
  color: #f1f1f1;
  z-index: 100;
  transform: translateY(50px);
  transition: transform 0.3s ease;
}
.product-card:hover button {
  transform: translateY(0);
}
.product-card:hover .wishlist {
  transform: translateX(0);
}
.product-card:hover {
  color: #222;
  transform: translate(0px, -8px);
}
@media (max-width: 900px) {
  .product-card {
    width: 300px;
  }
  .product-card .wishlist {
    transform: translateX(0);
  }
  .product-card .cart-btn > button {
    transform: translateY(0);
  }
  .product-card .product-description {
    margin-top: 10px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .product-card {
    width: 280px;
  }
  .product-card .wishlist {
    transform: translateX(0);
  }
  .product-card .cart-btn > button {
    transform: translateY(0);
  }
  .product-card .product-description {
    margin-top: 10px;
    text-align: center;
  }
}

/*----------------------Brand card styles-----------------------*/
.brand-card {
  position: relative;
  overflow: hidden;
  width: 350px;
  max-height: 480px;
  /*color: #222;*/
  transition: 0.4s;
  border-radius: 0;
  /*background: #666;*/
  /*.cart-btn {
        margin-bottom: 15px;
        overflow-y: hidden;

        & button {
            display: block;
            width: 100%;
            border: none;
            outline: none;
            background-color: rgba(116, 188, 31, .5);
            padding: 10px 0;
            color: $light-clr;
            z-index: 100;
            transform: translateY(50px);
            transition: transform 0.3s ease;
        }
    }*/
  /*&:hover button {
        transform: translateY(0);
    }*/
}
.brand-card .product-image {
  width: 100%;
  height: 250px;
  cursor: pointer;
  /*margin-top: 15px;*/
  background: #222;
}
.brand-card .card-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 0;
}
.brand-card .sale {
  display: grid;
  position: absolute;
  top: 10px;
  left: 10px;
  color: #f1f1f1;
  font-size: 14px;
  place-content: center;
  border-radius: 50%;
  font-weight: 600;
  background: #74bc1f;
}
.brand-card .wishlist {
  position: absolute;
  top: 0;
  right: 0;
  background: #f1f1f1;
  transform: translateX(50px);
  transition: transform 0.3s ease;
}
.brand-card .wishlist i {
  cursor: pointer;
}
.brand-card .wishlist i:hover {
  color: #74bc1f;
}
.brand-card .brand-wishlist {
  position: absolute;
  top: 0;
  right: 0;
  background: #000;
  transform: translateX(50px);
  transition: transform 0.3s ease;
}
.brand-card .brand-wishlist i {
  cursor: pointer;
}
.brand-card .brand-wishlist i:hover {
  color: #44D62C;
}
.brand-card .product-description {
  width: 100%;
}
.brand-card .product-description p {
  font-size: 14px;
  line-height: 1.5;
}
.brand-card .card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
}
.brand-card .card-footer .details {
  font-size: 14px;
  color: #44D62C;
}
.brand-card .card-footer .price-text {
  color: #fff;
  font-size: 20px;
}
.brand-card .card-footer button {
  font-size: 14px;
  width: 130px;
  border: none;
  outline: none;
  background-color: #44D62C !important;
  padding: 10px 0;
  /*color: $light-clr;*/
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 10px;
  transition: transform 0.3s ease;
  z-index: 100;
}
.brand-card:hover .wishlist {
  transform: translateX(0);
}
.brand-card:hover .brand-wishlist {
  transform: translateX(0);
}
.brand-card:hover {
  color: #222;
  transform: translate(0px, -8px);
}
@media (max-width: 900px) {
  .brand-card {
    width: 300px;
    /*.cart-btn > button {
            transform: translateY(0);
        }*/
  }
  .brand-card .wishlist {
    transform: translateX(0);
  }
  .brand-card .brand-wishlist {
    transform: translateX(0);
  }
  .brand-card .product-description {
    margin-top: 10px;
    /*text-align: center;*/
  }
}
@media (max-width: 767px) {
  .brand-card {
    width: 280px;
    /*.cart-btn > button {
            transform: translateY(0);
        }*/
  }
  .brand-card .wishlist {
    transform: translateX(0);
  }
  .brand-card .brand-wishlist {
    transform: translateX(0);
  }
  .brand-card .product-description {
    margin-top: 10px;
    /*text-align: center;*/
  }
}

.navbar-toggler-icon {
  background-image: var(--mdb-navbar-toggler-icon-bg) !important;
  display: inline-block;
}

.cart-icon {
  position: relative;
  cursor: pointer;
}
.cart-icon span {
  min-width: 20px;
  text-align: center;
  background: #74bc1f;
  padding: 2.5px;
  position: absolute;
  top: 0px;
  right: -5px;
  font-size: 12px;
  line-height: 1;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .cart-icon i {
    margin: 0px !important;
  }
}

.hero-button {
  outline: 0;
  border: 0;
  height: 50px;
  width: 180px;
  cursor: pointer;
  font-size: 16px;
  color: #f1f1f1;
  background: #74bc1f;
  border-bottom: 3px solid #67a61c;
  flex-grow: 1;
}

.brand-hero-button {
  outline: 0;
  border: 0;
  height: 50px;
  width: 180px;
  cursor: pointer;
  font-size: 16px;
  color: #000;
  background: #44D62C;
  border-radius: 10px;
  text-transform: uppercase;
  /*border-bottom: 3px solid #67a61c;*/
  flex-grow: 1;
  font-weight: bold;
}

.hero-parent {
  position: relative;
}
.hero-parent .hero-child {
  position: absolute;
  top: 50px;
  left: 200px;
}
.hero-parent .image-container {
  position: relative;
}
.hero-parent .image-container img {
  width: 100%;
  height: 600px;
  object-fit: fill;
}
.hero-parent .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  opacity: 0.9;
  pointer-events: none;
}
@media (max-width: 767px) {
  .hero-parent span {
    color: #0f0f0f;
  }
  .hero-parent h3 {
    color: #0f0f0f;
  }
  .hero-parent .hero-child {
    display: none;
  }
  .hero-parent .hero-child .hide {
    display: none;
  }
  .hero-parent .image-container {
    position: relative;
  }
  .hero-parent .image-container img {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
  .hero-parent .overlay {
    display: none;
  }
}

.product-card-container {
  width: 100%;
  height: 100%;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

/*---------------------------product view styles--------------------------------*/
.single-product-main-content {
  margin: 20px 0;
}
@media (min-width: 768px) {
  .single-product-main-content {
    margin: 75px 0;
  }
}
.single-product-main-content .layout {
  max-width: calc(100% - 20px);
  margin: 0 auto;
}
@media (min-width: 768px) {
  .single-product-main-content .layout {
    max-width: 1200px;
  }
}
.single-product-main-content .layout .single-product-page {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1000px) {
  .single-product-main-content .layout .single-product-page {
    flex-direction: row;
  }
}
.single-product-main-content .layout .single-product-page .left {
  background-color: #fff;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .single-product-main-content .layout .single-product-page .left {
    width: 600px;
    height: 600px;
  }
}
.single-product-main-content .layout .single-product-page .left img {
  width: 100%;
  height: 85%;
  display: block;
  object-fit: contain;
}
.single-product-main-content .layout .single-product-page .left .option {
  width: 100%;
  height: 90px;
  overflow-x: auto;
}
.single-product-main-content .layout .single-product-page .left .option img {
  max-width: 90px;
  height: auto;
}
.single-product-main-content .layout .single-product-page .left .option::-webkit-scrollbar {
  height: 4px;
}
.single-product-main-content .layout .single-product-page .left .option::-webkit-scrollbar-track {
  background-color: #ffffff;
}
.single-product-main-content .layout .single-product-page .left .option::-webkit-scrollbar-thumb {
  background-color: #74bc1f;
  border-radius: 6px;
}
.single-product-main-content .layout .single-product-page .left .option::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
.single-product-main-content .layout .single-product-page .brand-left {
  background-color: #000;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .single-product-main-content .layout .single-product-page .brand-left {
    width: 600px;
    height: 600px;
  }
}
.single-product-main-content .layout .single-product-page .brand-left img {
  width: 100%;
  height: 85%;
  display: block;
  object-fit: contain;
}
.single-product-main-content .layout .single-product-page .brand-left .option {
  width: 100%;
  height: 90px;
  overflow-x: auto;
}
.single-product-main-content .layout .single-product-page .brand-left .option img {
  max-width: 90px;
  height: auto;
}
.single-product-main-content .layout .single-product-page .brand-left .option::-webkit-scrollbar {
  height: 4px;
}
.single-product-main-content .layout .single-product-page .brand-left .option::-webkit-scrollbar-track {
  background-color: #000000;
}
.single-product-main-content .layout .single-product-page .brand-left .option::-webkit-scrollbar-thumb {
  background-color: #74bc1f;
  border-radius: 6px;
}
.single-product-main-content .layout .single-product-page .brand-left .option::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
.single-product-main-content .layout .single-product-page .right {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
@media (min-width: 768px) {
  .single-product-main-content .layout .single-product-page .right {
    padding: 0 35px;
  }
}
.single-product-main-content .layout .single-product-page .right .name {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .single-product-main-content .layout .single-product-page .right .name {
    font-size: 24px;
    line-height: 32px;
  }
}
.single-product-main-content .layout .single-product-page .right .price {
  font-size: 30px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: 15px;
}
.single-product-main-content .layout .single-product-page .right .desc {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  color: #6b6b6b;
  letter-spacing: 1px;
}
.single-product-main-content .layout .single-product-page .right .desc ul {
  padding-left: 16px !important;
}
@media (min-width: 768px) {
  .single-product-main-content .layout .single-product-page .right .desc {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.single-product-main-content .layout .single-product-page .right .cart-buttons {
  display: flex;
}
@media (min-width: 768px) {
  .single-product-main-content .layout .single-product-page .right .cart-buttons {
    margin-top: 30px;
  }
}
.single-product-main-content .layout .single-product-page .right .cart-buttons .quantity-buttons {
  width: fit-content;
  display: flex;
  border: 2px solid rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  height: 50px;
}
.single-product-main-content .layout .single-product-page .right .cart-buttons .quantity-buttons span {
  font-size: 18px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #6b6b6b;
}
.single-product-main-content .layout .single-product-page .right .cart-buttons .quantity-buttons span:nth-child(1) {
  border-right: 2px solid rgba(0, 0, 0, 0.2);
}
.single-product-main-content .layout .single-product-page .right .cart-buttons .quantity-buttons span:nth-child(2) {
  width: 60px;
}
.single-product-main-content .layout .single-product-page .right .cart-buttons .quantity-buttons span:nth-child(3) {
  border-left: 2px solid rgba(0, 0, 0, 0.2);
}
.single-product-main-content .layout .single-product-page .right .cart-buttons .add-to-cart-button {
  outline: 0;
  border: 0;
  height: 50px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #f1f1f1;
  background: #74bc1f;
  border-bottom: 3px solid #67a61c;
  flex-grow: 1;
}
@media (min-width: 768px) {
  .single-product-main-content .layout .single-product-page .right .cart-buttons .add-to-cart-button {
    flex-grow: unset;
  }
}
.single-product-main-content .layout .single-product-page .right .cart-buttons .add-to-cart-button i {
  margin-right: 10px;
}
.single-product-main-content .layout .single-product-page .right .divider {
  margin: 20px 0;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.single-product-main-content .layout .single-product-page .right .info-item .text-bold {
  font-size: 18px;
  font-weight: 500;
  display: block;
}
.single-product-main-content .layout .single-product-page .right .info-item .text-bold:nth-child(1) {
  margin-bottom: 20px;
}
.single-product-main-content .layout .single-product-page .right .info-item .text-bold span {
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  color: #6b6b6b;
}
.single-product-main-content .layout .single-product-page .right .info-item .text-bold i {
  margin: 0 5px;
}

/*-------------------------cart styles-----------------------------*/
.cart-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000000001;
  display: flex;
  justify-content: flex-end;
}
.cart-panel .opac-layer {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cart-panel .cart-content {
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  animation: slideCartWindow 0.3s ease forwards;
}
.cart-panel .cart-content ::-webkit-scrollbar {
  width: 8px;
}
.cart-panel .cart-content ::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 4px;
}
.cart-panel .cart-content ::-webkit-scrollbar-thumb:hover {
  background-color: #3e6411;
}
@media (min-width: 768px) {
  .cart-panel .cart-content {
    width: 340px;
  }
}
.cart-panel .cart-content .cart-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.cart-panel .cart-content .cart-header .heading {
  flex-grow: 1;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
.cart-panel .cart-content .cart-header .close-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.cart-panel .cart-content .cart-header .close-btn i {
  font-size: 18px;
}
.cart-panel .cart-content .cart-header .close-btn .text {
  text-transform: uppercase;
  font-size: 14px;
}
.cart-panel .cart-content .cart-header .close-btn:hover {
  opacity: 0.5;
}
.cart-panel .cart-content .empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 100px;
}
.cart-panel .cart-content .empty-cart i {
  font-size: 120px;
  opacity: 0.1;
}
.cart-panel .cart-content .empty-cart .return-cta {
  outline: 0;
  border: 0;
  height: 40px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 13px;
  color: #f1f1f1;
  background: #74bc1f;
  border-bottom: 3px solid #67a61c;
}
.cart-panel .cart-content .empty-cart .return-cta i {
  margin-right: 10px;
}
.cart-panel .cart-content .scrollable-cart {
  height: 100vh;
  overflow-y: auto;
  padding-right: 15px;
}
.cart-panel .cart-content .cart-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.cart-panel .cart-content .cart-footer .subtotal {
  padding: 20px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
}
.cart-panel .cart-content .cart-footer .subtotal .text {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
.cart-panel .cart-content .cart-footer .subtotal .text.total {
  color: #74bc1f;
}
.cart-panel .cart-content .cart-footer .button {
  padding: 20px 15px;
}
.cart-panel .cart-content .cart-footer .button .checkout-cta {
  outline: 0;
  border: 0;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #f1f1f1;
  background: #74bc1f;
  border-bottom: 3px solid #67a61c;
  text-transform: uppercase;
}
.cart-panel .cart-content .cart-footer .button .checkout-cta i {
  margin-right: 10px;
}

.coupon-button {
  padding: 10px 0;
}
.coupon-button .coupon-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
}
.coupon-button .coupon-button {
  background-color: #74bc1f;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.coupon-button .coupon-button:hover {
  background-color: #66a61b;
}
.coupon-button .coupon-message {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}
.coupon-button .coupon-error {
  color: #ff0000;
  font-size: 14px;
}

@keyframes slideCartWindow {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
/*-----------------------cart items-------------------------*/
.cart-products {
  flex-grow: 1;
}
.cart-products .search-result-item {
  padding: 20px 15px;
  display: flex;
  gap: 10px;
}
.cart-products .search-result-item .image-container {
  background-color: rgba(0, 0, 0, 0.05);
  width: 60px;
  height: 60px;
  flex-shrink: 0;
}
.cart-products .search-result-item .image-container img {
  width: 100%;
  height: 100%;
}
.cart-products .search-result-item .prod-details {
  overflow: hidden;
  position: relative;
}
.cart-products .search-result-item .prod-details .name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 10px;
  font-weight: 600;
  display: block;
  padding-right: 25px;
}
.cart-products .search-result-item .prod-details .text {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  font-weight: 600;
}
.cart-products .search-result-item .prod-details .text .highlight {
  color: #74bc1f;
}
.cart-products .search-result-item .prod-details i {
  position: absolute;
  top: -4px;
  right: 0;
  cursor: pointer;
}
.cart-products .search-result-item .prod-details .quantity-buttons {
  width: fit-content;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 30px;
  margin-bottom: 8px;
}
.cart-products .search-result-item .prod-details .quantity-buttons span {
  font-size: 14px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #6b6b6b;
}
.cart-products .search-result-item .prod-details .quantity-buttons span:nth-child(1) {
  font-size: 18px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.cart-products .search-result-item .prod-details .quantity-buttons span:nth-child(2) {
  width: 40px;
}
.cart-products .search-result-item .prod-details .quantity-buttons span:nth-child(3) {
  font-size: 18px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.cart-products .search-result-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-wrapper input.border-style {
  border: solid 1px #ced4da;
  border-radius: 4px;
  width: 100%;
}

@media (max-width: 767px) {
  .graphs {
    flex-direction: column;
  }
}
/*--------------------------------wishList items----------------------------------*/
.wishlist-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 1rem;
  position: relative;
}
.wishlist-item i {
  cursor: pointer;
}
.wishlist-item .image-container {
  background-color: rgba(0, 0, 0, 0.05);
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  margin-right: 1rem;
  border-radius: 4px;
}
.wishlist-item .image-container img {
  width: 100%;
  height: 100%;
}
.wishlist-item h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.wishlist-item p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 0.5rem;
}
.wishlist-item button {
  outline: 0;
  border: 0;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #f1f1f1;
  background: #74bc1f;
  border-bottom: 3px solid #67a61c;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}
@media (max-width: 800px) {
  .wishlist-item {
    flex-direction: column;
  }
  .wishlist-item .product {
    margin-top: 15px;
  }
  .wishlist-item .image-container {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .wishlist-item h3 {
    padding: 0px 10px;
  }
  .wishlist-item button {
    width: 100%;
  }
  .wishlist-item .xmark {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.empty-wish {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 100px;
}
.empty-wish svg {
  font-size: 120px;
  opacity: 0.1;
}

/*------------------------Razer page styles-----------------------------*/
.razer-container {
  max-width: 1300px;
  width: 100%;
  /*.slider-wrapper .image-list {
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        gap: 18px;
        font-size: 0;
        list-style: none;
        margin-bottom: 30px;
        overflow-x: auto;
        scrollbar-width: none;
    }*/
}
.razer-container .slider-wrapper {
  position: relative;
}
.razer-container .slider-wrapper .slide-button {
  position: absolute;
  top: 50%;
  outline: none;
  border: none;
  height: 50px;
  width: 50px;
  z-index: 5;
  color: #44D62C;
  display: flex;
  cursor: pointer;
  font-size: 2.2rem;
  background: #000;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: translateY(-50%);
}
.razer-container .slider-wrapper .slide-button:hover {
  background: #404040;
}
.razer-container .slider-wrapper .slide-button#prev-slide {
  left: -25px;
  display: none;
}
.razer-container .slider-wrapper .slide-button#next-slide {
  right: -25px;
}
.razer-container .slider-wrapper .image-list::-webkit-scrollbar {
  display: none;
}
.razer-container .slider-wrapper .image-list .image-item {
  width: 350px;
  height: auto;
  object-fit: cover;
}
@media only screen and (max-width: 1023px) {
  .razer-container .slider-wrapper .slide-button {
    display: none !important;
  }
  .razer-container .slider-wrapper .image-list {
    gap: 10px;
    margin-bottom: 15px;
    scroll-snap-type: x mandatory;
  }
  .razer-container .slider-wrapper .image-list .image-item {
    width: 280px;
    height: 380px;
  }
  .razer-container .slider-scrollbar .scrollbar-thumb {
    width: 20%;
  }
}

.razer-container2 {
  max-width: 1300px;
  width: 100%;
  /*.slider-wrapper-new .image-list {
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        gap: 18px;
        font-size: 0;
        list-style: none;
        margin-bottom: 30px;
        overflow-x: auto;
        scrollbar-width: none;
    }*/
}
.razer-container2 .slider-wrapper-new {
  position: relative;
}
.razer-container2 .slider-wrapper-new .slide-button-new {
  position: absolute;
  top: 50%;
  outline: none;
  border: none;
  height: 50px;
  width: 50px;
  z-index: 5;
  color: #44D62C;
  display: flex;
  cursor: pointer;
  font-size: 2.2rem;
  background: #000;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: translateY(-50%);
}
.razer-container2 .slider-wrapper-new .slide-button-new:hover {
  background: #404040;
}
.razer-container2 .slider-wrapper-new .slide-button-new#prev-slide-new {
  left: -25px;
  display: none;
}
.razer-container2 .slider-wrapper-new .slide-button-new#next-slide-new {
  right: -25px;
}
.razer-container2 .slider-wrapper-new .image-list::-webkit-scrollbar {
  display: none;
}
.razer-container2 .slider-wrapper-new .image-list .image-item {
  width: 350px;
  height: auto;
  object-fit: cover;
}
@media only screen and (max-width: 1023px) {
  .razer-container2 .slider-wrapper-new .slide-button-new {
    display: none !important;
  }
  .razer-container2 .slider-wrapper-new .image-list {
    gap: 10px;
    margin-bottom: 15px;
    scroll-snap-type: x mandatory;
  }
  .razer-container2 .slider-wrapper-new .image-list .image-item {
    width: 280px;
    height: 380px;
  }
  .razer-container2 .slider-scrollbar .scrollbar-thumb {
    width: 20%;
  }
}

/*------------------------Brand product page styles-----------------------------*/
.brand-products {
  display: flex;
}
.brand-products .product-left {
  flex: 1;
  position: sticky;
  height: 100%;
  top: 200px;
}
.brand-products .product-left .filter-item {
  margin-bottom: 30px;
}
.brand-products .product-left .filter-item button {
  display: block;
  margin-bottom: 10px;
  border: none;
  background: #44D62C;
  color: #f1f1f1;
  padding: 4px 10px;
  width: 87%;
}
.brand-products .product-left .filter-item .category {
  margin-bottom: 10px;
  color: #fff;
}
.brand-products .product-left .filter-item .category .category-header {
  cursor: pointer;
}
.brand-products .product-left .filter-item .category .category-header span {
  display: grid;
  place-content: center;
  width: 12px;
  height: 12px;
  padding: 10px;
  background: #0f0f0f;
  border-radius: 50%;
  font-weight: bold;
}
.brand-products .product-left .filter-item .category .category-header:hover {
  color: #44D62C;
}
.brand-products .product-left .filter-item .category .subcategory-list {
  display: block;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
.brand-products .product-left .filter-item .category .subcategory-list.collapsed {
  max-height: 500px;
}
.brand-products .product-left .filter-item .category .subcategory-list .subcategory {
  margin-left: 20px;
  margin-top: 8px;
  display: flex;
}
.brand-products .product-left .filter-item .category .subcategory-list .subcategory label {
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
}
.brand-products .product-left .filter-item h5 {
  font-weight: bold;
  margin-bottom: 20px;
}
.brand-products .product-left .filter-item .input-item {
  margin-bottom: 10px;
  display: flex;
}
.brand-products .product-left .filter-item .input-item label {
  margin-left: 10px;
}
.brand-products .product-right {
  flex: 3;
}
.brand-products .product-right .product-right-img {
  width: 100%;
}
.brand-products .product-right .product-right-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media screen and (max-width: 800px) {
  .brand-products {
    flex-direction: column;
  }
  .brand-products button {
    display: block;
  }
  .brand-products .product-left {
    position: initial;
  }
}

/*------------------------Product page styles-----------------------------*/
.products {
  display: flex;
}
.products button {
  display: none;
}
.products .product-left {
  flex: 1;
  position: sticky;
  height: 100%;
  top: 200px;
}
.products .product-left .filter-item {
  margin-bottom: 30px;
}
.products .product-left .filter-item button {
  display: block;
  margin-bottom: 10px;
  border: none;
  background: #74bc1f;
  color: #f1f1f1;
  padding: 4px 10px;
  width: 87%;
}
.products .product-left .filter-item .category {
  margin-bottom: 10px;
}
.products .product-left .filter-item .category .category-header {
  cursor: pointer;
}
.products .product-left .filter-item .category .category-header span {
  display: grid;
  place-content: center;
  width: 12px;
  height: 12px;
  padding: 10px;
  background: #f1f1f1;
  border-radius: 50%;
  font-weight: bold;
}
.products .product-left .filter-item .category .category-header:hover {
  color: #74bc1f;
}
.products .product-left .filter-item .category .subcategory-list {
  display: block;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
.products .product-left .filter-item .category .subcategory-list.collapsed {
  max-height: 500px;
}
.products .product-left .filter-item .category .subcategory-list .subcategory {
  margin-left: 20px;
  margin-top: 8px;
  display: flex;
}
.products .product-left .filter-item .category .subcategory-list .subcategory label {
  cursor: pointer;
  margin-left: 10px;
}
.products .product-left .filter-item h5 {
  font-weight: bold;
  margin-bottom: 20px;
}
.products .product-left .filter-item .input-item {
  margin-bottom: 10px;
  display: flex;
}
.products .product-left .filter-item .input-item label {
  margin-left: 10px;
}
.products .product-right {
  flex: 3;
}
@media screen and (max-width: 800px) {
  .products {
    flex-direction: column;
  }
  .products button {
    display: block;
  }
  .products .product-left {
    position: initial;
  }
}

.pagination-container {
  margin: 100px auto;
  text-align: center;
  height: 50px;
  width: 100%;
}

.pagination {
  position: relative;
  display: flex;
  justify-content: center;
  --mdb-pagination-active-bg: none;
  --mdb-pagination-active-box-shadow: none;
  --mdb-pagination-hover-bg: none;
}
.pagination .active > .page-link {
  background: #74bc1f;
  color: #f1f1f1;
  border-radius: 24px;
}
.pagination button {
  position: relative;
  display: inline-block;
  color: #2c3e50;
  text-decoration: none;
  font-size: 1rem;
  padding: 8px 20px 8px;
  border: none;
  background-color: transparent;
}
.pagination button:before {
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  top: 0;
  left: 0;
  background-color: #74bc1f;
  border-radius: 24px;
  transform: scale(0);
  transition: all 0.2s;
}
.pagination button:hover, .pagination button.pagination-active {
  color: #f1f1f1;
}
.pagination button:hover:before, .pagination button.pagination-active:before {
  transform: scale(1);
}
.pagination.pagination-active {
  color: #f1f1f1;
}
.pagination.pagination-active:before {
  transform: scale(1);
}
.pagination .pagination-newer {
  margin-right: 20px;
}
.pagination .pagination-older {
  margin-left: 20px;
}
@media screen and (max-width: 600px) {
  .pagination .pagination-older, .pagination .pagination-newer {
    padding: 8px 12px;
    font-size: 12px;
    border: 2px solid #333;
    width: 100px;
    margin: 0px;
  }
  .pagination .pagination-older:before, .pagination .pagination-newer:before {
    background-color: transparent;
  }
  .pagination .pagination-older:hover, .pagination .pagination-newer:hover {
    color: #333;
  }
  .pagination .ellipsis-button {
    display: none;
  }
}

/*----------------------product filter styles------------------------*/
.range-slider {
  margin-bottom: 0 5px;
  display: flex;
}

.rs-range {
  margin: 10px;
  -webkit-appearance: none;
}
.rs-range:focus {
  outline: none;
}
.rs-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: none;
  background: #74bc1f;
  border-radius: 0px;
  border: 0px solid #010101;
}
.rs-range::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: none;
  background: #74bc1f;
  border-radius: 0px;
  border: 0px solid #010101;
}
.rs-range::-webkit-slider-thumb {
  box-shadow: none;
  border: 1px solid #aaa;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  height: 22px;
  width: 12px;
  border-radius: 12px;
  background: rgb(255, 255, 255);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -10px;
}
.rs-range::-moz-range-thumb {
  box-shadow: none;
  border: 1px solid #aaa;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  height: 22px;
  width: 12px;
  border-radius: 12px;
  background: rgb(255, 255, 255);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -10px;
}
.rs-range::-moz-focus-outer {
  border: 0;
}

.carousel-control-next-icon::after,
.carousel-control-prev-icon::after {
  color: #74bc1f;
}

.carousel-indicators > button {
  background-color: #74bc1f !important;
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
}

.carousel-control-prev,
.carousel-control-next {
  z-index: 0;
}

.price-text {
  color: #74bc1f;
}

/*-----------------------------------image thumbnail styles--------------------------------------*/
.thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
.thumbnails .thumbnail-container {
  position: relative;
  display: inline-block;
}
.thumbnails .thumbnail-container .thumbnail {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border: 1px solid #ddd;
  transition: transform 0.2s ease-in-out;
}
.thumbnails .thumbnail-container .thumbnail:hover {
  transform: scale(1.1);
}
.thumbnails .thumbnail-container .thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.thumbnails .thumbnail-container i {
  position: absolute;
  top: 0;
  right: 4px;
  color: red;
}
.thumbnails button {
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
}
.thumbnails button i {
  color: #74bc1f;
}

.image-uploader {
  margin-top: 20px;
}
.image-uploader .upload-input {
  display: none;
  margin-bottom: 10px;
}
.image-uploader .uploaded-image {
  margin-top: 10px;
}
.image-uploader .uploaded-image img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/*-----------------------------------banner thumbnail styles--------------------------------------*/
.Bannerthumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
.Bannerthumbnails .thumbnail-container {
  position: relative;
  display: inline-block;
}
.Bannerthumbnails .thumbnail-container .thumbnail {
  width: 300px;
  height: 120px;
  overflow: hidden;
  border: 1px solid #ddd;
  transition: transform 0.2s ease-in-out;
}
.Bannerthumbnails .thumbnail-container .thumbnail:hover {
  transform: scale(1.1);
}
.Bannerthumbnails .thumbnail-container .thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Bannerthumbnails .thumbnail-container i {
  position: absolute;
  top: 0;
  right: 4px;
  color: red;
}
.Bannerthumbnails button {
  width: 300px;
  height: 120px;
  border: 1px solid #ddd;
}
.Bannerthumbnails button i {
  color: #74bc1f;
}

/*.image-uploader {
    margin-top: 20px;

    .upload-input {
        display: none;
        margin-bottom: 10px;
    }

    .uploaded-image {
        margin-top: 10px;

        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border: 1px solid #ccc;
            border-radius: 4px;
        }
    }
}*/
/*-------------------------Quick view modal styles----------------------------*/
.quick-view-modal {
  display: flex;
}
.quick-view-modal .quick-view-content {
  display: flex;
  align-items: center;
}
.quick-view-modal .quick-view-content-left {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quick-view-modal .quick-view-content-left img {
  max-width: 100%;
  height: 300px;
  max-height: 100%;
  object-fit: contain;
}
.quick-view-modal .quick-view-content-right {
  flex: 2;
  padding: 20px;
}
.quick-view-modal .quick-view-content-right h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.quick-view-modal .quick-view-content-right p {
  font-size: 1rem;
  margin-bottom: 20px;
}
.quick-view-modal .quick-view-content-right button {
  outline: 0;
  border: 0;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 16px;
  color: #f1f1f1;
  background: #74bc1f;
  border-bottom: 3px solid #67a61c;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}
.quick-view-modal .quick-view-content-right i {
  cursor: pointer;
}
@media (max-width: 400px) {
  .quick-view-modal {
    flex-direction: column;
  }
}

/*--------------------------------Brand styles---------------------------------*/
.brand-container {
  cursor: pointer;
  /*width: 300px;
    height: auto;*/
  border-radius: 10px;
  transition: box-shadow 0.3s;
}
@media (max-width: 768px) {
  .brand-container {
    /*box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;*/
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    /*box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;*/
  }
}
.brand-container:hover {
  /*box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;*/
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  /*box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;*/
}
.brand-container .brand-logo {
  width: 150px;
  height: 100px;
  object-fit: contain;
}
.brand-container .brand-name {
  margin-bottom: 10px;
  text-align: center;
}

.flex-charts {
  flex-wrap: nowrap;
}

.brand-image img {
  height: auto;
}
@media (max-width: 400px) {
  .brand-image img {
    max-width: 250px;
    max-height: 120px;
  }
}

.brand-Banner {
  width: 100%;
}
.brand-Banner img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
@media (max-width: 400px) {
  .brand-Banner img {
    max-width: 100%;
    max-height: 130px;
  }
}

.text-end label {
  margin-right: 10px;
  font-weight: bold;
  font-size: 14px;
  color: #555;
  /*text-shadow: .5px .5px .2px #fff;*/
}
.text-end .sort-selection--style {
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  /*background-image: url('path-to-arrow-image.png');*/
  background-repeat: no-repeat;
  background-position: right center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}
.text-end .sort-selection--style:hover {
  background-color: #f0f0f0;
}
.text-end .sort-selection--style:focus {
  outline: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.text-end label {
  margin-right: 10px;
  font-weight: bold;
  font-size: 14px;
  color: #555;
  /*text-shadow: .5px .5px .2px #fff;*/
}
.text-end .brand-sort-selection--style {
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #bbb;
  /*border-radius: 5px;*/
  background-color: #000;
  color: #fff;
  /*background-image: url('path-to-arrow-image.png');*/
  background-repeat: no-repeat;
  background-position: right center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}
.text-end .brand-sort-selection--style:hover {
  background-color: #555;
}
.text-end .brand-sort-selection--style:focus {
  outline: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

/*--------------------------------Category styles---------------------------------*/
.category-container {
  /*cursor: pointer;*/
  width: 250px;
  /*height: auto;*/
  border-radius: 10px;
  transition: box-shadow 0.3s;
  /*.header {
        cursor: pointer;*/
  /*}*/
}
@media (max-width: 768px) {
  .category-container {
    /*box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;*/
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    /*box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;*/
  }
}
.category-container:hover {
  /*box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;*/
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  /*box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;*/
}
.category-container .card-header {
  padding: 0 0 10px 0;
}
.category-container .category-image {
  width: 100%;
  height: 120px;
  object-fit: cover;
}
.category-container .category-title {
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}
.category-container .category-title:hover {
  color: #74bc1f;
}
.category-container .subcategories .subcategory-title {
  cursor: pointer;
  margin-bottom: 10px;
  text-align: center;
}
.category-container .subcategories .subcategory-title:hover {
  color: #74bc1f;
}

/*--------------------------------order history items----------------------------------*/
.order-card-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.order-card-container .box {
  height: 150px;
  min-width: 230px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.order-card-container .box .icon-container {
  display: grid;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(116, 188, 31, 0.2);
  place-items: center;
}
.order-card-container .box .icon-container i {
  font-size: 40px;
  color: #74bc1f;
}
.order-card-container .box .text h2 {
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 0;
}
.order-card-container .box .text p {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
  color: #aaa;
}
.order-card-container .box:hover {
  transform: scale(1.02);
}

.history .image-container {
  background-color: rgba(0, 0, 0, 0.05);
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  margin-right: 1rem;
  border-radius: 4px;
}
.history .image-container img {
  width: 100%;
  height: 100%;
}

/*--------------------------------order history items----------------------------------*/
.order-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 1rem;
  position: relative;
  /*p {
        font-size: 1rem;
        color: #555;
        margin-bottom: 0;
    }*/
}
.order-item span {
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  background: #f1f1f1;
  border-radius: 50%;
  cursor: pointer;
}
.order-item strong {
  display: none;
}
@media (max-width: 800px) {
  .order-item {
    flex-direction: column;
    align-items: flex-start;
  }
  .order-item div {
    width: 100%;
  }
  .order-item span {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .order-item strong {
    display: inline-block;
  }
}

.empty-history {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 100px;
}
.empty-history svg {
  height: 200px;
  opacity: 0.1;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

.nav-tabs .nav-link.active {
  color: #74bc1f;
  border-color: #74bc1f;
}

.rating input[type=radio] {
  display: none;
}
.rating .star {
  cursor: pointer;
}

.reviews-container {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 15px;
}
.reviews-container .customer-review {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
}
.reviews-container .customer-review .rating {
  color: #ffc107;
}
.reviews-container .customer-review .star {
  font-size: 24px;
  margin-right: 4px;
}
.reviews-container .customer-review .customer-name {
  font-weight: bold;
  margin-bottom: 5px;
}
.reviews-container .customer-review .review-date {
  color: #888;
  font-size: 14px;
  margin-bottom: 10px;
}
.reviews-container .customer-review .review-comment {
  font-size: 16px;
  line-height: 1.4;
}

.no-reviews {
  font-size: 18px;
  color: #888;
  text-align: center;
  margin-top: 20px;
}

/*-------------------------------Scroll to top styles-------------------------------*/
.scroll-to-top {
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 900;
  transition: opacity 0.3s;
  opacity: 0.5;
}

.scroll-to-top.hide {
  opacity: 0;
  pointer-events: none;
}

.scroll-to-top button {
  background-color: #74bc1f;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  text-align: center;
}

.scroll-to-top button:hover {
  background-color: #66a61b;
}

.fixed {
  position: fixed;
  bottom: 40px;
  right: 40px;
}

.checkout-btn {
  outline: 0;
  border: 0;
  height: 40px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  color: #f1f1f1;
  background: #74bc1f;
  border: 2px solid #67a61c;
  text-transform: uppercase;
  border-radius: 4px;
  /*i {
      margin-right: 10px;
    }
  */
}
.checkout-btn.small {
  width: auto;
  height: auto;
  font-size: 12px;
  padding: 4px 15px;
}
.checkout-btn:hover {
  background-color: #67a61c;
}

.complete button {
  height: 40px;
  width: 100%;
  margin-bottom: 8px;
  font-size: 14px;
}
.complete .checkout-btn {
  outline: 0;
  border: 0;
  /*height: 40px;*/
  /*width: 200px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /*font-size: 14px;*/
  color: #f1f1f1;
  background: #74bc1f;
  border: 2px solid #67a61c;
  text-transform: uppercase;
  border-radius: 4px;
}
.complete .checkout-btn i {
  margin-right: 10px;
}
.complete .checkout-btn:hover {
  background-color: #67a61c;
}

.faq ul {
  list-style: none;
  padding: 0;
  transition: box-shadow 0.3s;
}
.faq ul div {
  margin-bottom: 20px;
  border: 1px solid #74bc1f;
  border-radius: 10px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.faq ul div button {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}
.faq ul div strong {
  font-size: 18px;
  margin-right: 10px;
  vertical-align: middle;
}
.faq ul div p, .faq ul div ol, .faq ul div ul {
  font-size: 16px;
  display: inline;
}

/*--------------------------------Promotions styles------------------------------------*/
.promotions-cards {
  max-width: 800px;
  margin: 0 auto;
}

.promotions-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 0 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.promotions-card .promotions-image {
  max-width: 100%;
  height: auto;
  /*max-height: 300px;*/
}
.promotions-card .promotions-content {
  margin-top: 20px;
}
.promotions-card .promotions-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.promotions-card .promotions-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}
.promotions-card .promotions-button {
  outline: 0;
  border: 0;
  height: 50px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #f1f1f1;
  background: #74bc1f;
  border-bottom: 3px solid #67a61c;
  flex-grow: 1;
  margin: auto;
}

/*----------------------------------Toggle switch styles-------------------------------------*/
.toggle-container {
  display: flex;
  /*text-align: start;*/
  align-items: center;
  /*height: 100%;*/
  /*max-width: 200px;*/
}
.toggle-container strong {
  font-size: 12px;
}

.toggle-switch {
  position: relative;
  width: 65px;
  display: inline-block;
  text-align: left;
  /*top: 8px;*/
}

.checkbox {
  display: none;
}

.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}

.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 26px;
  padding: 0;
  line-height: 26px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}

.inner:before {
  content: "";
  padding-left: 10px;
  background-color: #74bc1f;
  color: #fff;
}

.inner:after {
  content: "";
  padding-right: 10px;
  background-color: #74bc1f;
  color: #fff;
  text-align: right;
}

.switch {
  display: block;
  width: 20px;
  height: 20px;
  margin: 3px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 38px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.checkbox:checked + .label .inner {
  margin-left: 0;
}

.checkbox:checked + .label .switch {
  right: 0px;
}

/*-----------------------------Contact message styles--------------------------------*/
.contact-details div {
  margin-bottom: 10px;
  font-weight: normal;
}
.contact-details div:last-child {
  margin-bottom: 0;
}
.contact-details div strong {
  /*font-weight: normal;*/
  margin-right: 5px;
}
.contact-details div label {
  font-weight: bold;
  margin-right: 5px;
}

/*-----------------------------Speech bubble styles--------------------------------*/
.speech-bubble {
  width: 100%;
  margin: 50px auto;
  background: #00bfb6;
  padding: 0px 20px 20px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  position: relative;
}
.speech-bubble small {
  font-size: 14px;
}
.speech-bubble.sb1:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #00bfb6;
  border-right: 10px solid transparent;
  border-top: 10px solid #00bfb6;
  border-bottom: 10px solid transparent;
  left: 19px;
  bottom: -19px;
}
.speech-bubble.sb2:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid #00bfb6;
  border-top: 10px solid #00bfb6;
  border-bottom: 10px solid transparent;
  right: 19px;
  bottom: -19px;
}

.admin-quantity-buttons {
  align-self: center;
  width: fit-content;
  display: flex;
  border: 2px solid rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  height: 30px;
}
.admin-quantity-buttons span {
  font-size: 13px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #6b6b6b;
}
.admin-quantity-buttons span:nth-child(1) {
  border-right: 2px solid rgba(0, 0, 0, 0.2);
}
.admin-quantity-buttons span:nth-child(2) {
  width: 40px;
}
.admin-quantity-buttons span:nth-child(3) {
  border-left: 2px solid rgba(0, 0, 0, 0.2);
}

.razor-title h1 {
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #44D62C;
}
.razor-title h2 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #fff;
}
.razor-title h3 {
  font-size: 18px;
  color: #bbb;
  font-weight: lighter;
}

.razer-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.razer-categories div {
  cursor: pointer;
}
.razer-categories div img {
  width: 130px;
  height: auto;
  margin-bottom: 15px;
}
.razer-categories div h6 {
  color: #fff;
  font-weight: 200;
}

.brand-quantity-buttons {
  width: fit-content;
  display: flex;
  border: 2px solid #ddd;
  margin-right: 10px;
  height: 50px;
}
.brand-quantity-buttons span {
  font-size: 18px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
}
.brand-quantity-buttons span:nth-child(1) {
  border-right: 2px solid #ddd;
}
.brand-quantity-buttons span:nth-child(2) {
  width: 60px;
}
.brand-quantity-buttons span:nth-child(3) {
  border-left: 2px solid #ddd;
}

.brand-add-to-cart-button {
  outline: 0;
  border: 0;
  height: 50px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  background: #44D62C;
  border-radius: 10px;
  /*border-bottom: 3px solid #67a61c;*/
  flex-grow: 1;
  /*i {
        margin-right: 10px;
    }*/
}
@media (min-width: 768px) {
  .brand-add-to-cart-button {
    flex-grow: unset;
  }
}

.table-text th {
  color: #fff;
}
.table-text td {
  color: #fff;
}

.brand-desc {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  color: #fff;
  letter-spacing: 1px;
}
.brand-desc span {
  color: #fff !important;
}
.brand-desc ul {
  padding-left: 16px;
}
@media (min-width: 768px) {
  .brand-desc {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}

@media (max-width: 800px) {
  .razer-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .razer-categories div {
    cursor: pointer;
  }
  .razer-categories div img {
    width: 80px;
    height: auto;
    margin-bottom: 15px;
  }
  .razer-categories div h6 {
    color: #fff;
    font-size: 12px;
  }
  .not-show {
    display: none;
  }
}
@media (max-width: 768px) {
  .flex-charts {
    flex-wrap: wrap;
  }
  .nav-tabs .nav-item {
    width: 110px;
  }
  .nav-tabs .nav-item .nav-link {
    padding: 10px;
  }
}
@media (max-width: 500px) {
  .razer-categories {
    display: flex;
    /*flex-direction: column;*/
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .razer-categories div {
    cursor: pointer;
  }
  .razer-categories div img {
    width: 50px;
    height: auto;
    margin-bottom: 15px;
  }
  .razer-categories div h6 {
    color: #fff;
    font-size: 12px;
  }
  .cart-buttons {
    flex-direction: column;
    gap: 10px;
  }
}
.e-flat.e-icon-btn {
  margin: 0;
  padding: 4px 6px;
}

header .navbar-brand img {
  max-height: 60px;
}

footer {
  margin-top: 40px;
  background-color: #ececec;
  color: #000;
  padding: 20px 0;
  width: 100%;
  font-size: 0.9em;
}
footer h3 {
  font-weight: 300;
  font-size: 0.9em;
  text-transform: uppercase;
  border-bottom: 1px solid #000;
  padding-bottom: 6px;
  margin-bottom: 16px;
}
footer .social-icons a {
  margin: 0 10px;
  color: #000;
  font-size: 2.5em;
  transition: ease-in-out all 0.3s;
  text-decoration: none;
}
footer .social-icons a:hover {
  color: #74bd43;
  text-decoration: none;
}
footer .social-icons a:hover svg {
  fill: #74bd43;
}
footer .social-icons a svg {
  transition: ease-in-out all 0.3s;
}
footer .brand-heading {
  font-weight: 300;
  font-size: 0.9em;
  text-transform: uppercase;
  border-bottom: 1px solid #44D62C;
  padding-bottom: 6px;
  margin-bottom: 16px;
}
footer .brand-social-icons a {
  margin: 0 10px;
  color: #fff;
  font-size: 2.5em;
  transition: ease-in-out all 0.3s;
  text-decoration: none;
}
footer .brand-social-icons a:hover {
  color: #44D62C;
  text-decoration: none;
}
footer .brand-social-icons a:hover svg {
  fill: #44D62C;
}
footer .brand-social-icons a svg {
  transition: ease-in-out all 0.3s;
}
